import Mock from '../mock';
import { IDivision } from '@dex/models/division.model';

const DivisionsDB: { list: IDivision[] } = {
  list: [
    {
      id: 'd44ed184-5ffc-4fd6-b6fc-51591343f5d7',
      name: 'Apex Legends',
      logoUrl: '/assets/images/logos/games/logo_apex-legends.png',
      isActive: true,
      sub: [
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: 'f91aea46-12c4-4652-9939-cef40af795b4',
      name: 'Call of Duty',
      logoUrl: '/assets/images/logos/games/logo_call-of-duty.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: '4d2cba2a-954c-4714-8044-396a77c5874a',
      name: 'Counter-Strike: Global Offensive',
      logoUrl: '/assets/images/logos/games/logo_counter-strike-global-offensive.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: '2040158a-efca-4244-90bd-cc855097785f',
      name: 'Counter-Strike 2',
      logoUrl: '/assets/images/logos/games/logo_counter-strike-2.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: 'b9c2feb0-09fc-4875-91eb-5e316c66fe80',
      name: 'Dota 2',
      logoUrl: '/assets/images/logos/games/logo_dota-2.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: 'e95ed7d7-faef-4adc-b94e-46ed2f2e268f',
      name: 'FIFA 23',
      logoUrl: '/assets/images/logos/games/logo_fifa-23.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: '3d91ac69-2f04-4391-b17c-a696e970ba74',
      name: 'Fortnite',
      logoUrl: '/assets/images/logos/games/logo_fortnite.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: '017c9baf-cbdd-4a62-9a0e-40210b7557ea',
      name: 'Hearthstone',
      logoUrl: '/assets/images/logos/games/logo_hearthstone.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: '6b197661-cc9f-41c0-9403-30e75d327d43',
      name: 'League of Legends',
      logoUrl: '/assets/images/logos/games/logo_league_of_legends.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: 'ff4ed370-5a0c-4d21-ad71-a5aea33df9d8',
      name: 'League of Legends: Soul Fighter',
      logoUrl: '/assets/images/logos/games/logo_league-of-legends-soul-fighter.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: '9e9fe6d3-e9d0-45ab-8cf8-b37465dfe3c0',
      name: 'Overwatch',
      logoUrl: '/assets/images/logos/games/logo_overwatch.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: '5d3ede65-4392-4958-b82b-e1fc0776ba4f',
      name: 'PUBG',
      logoUrl: '/assets/images/logos/games/logo_pubg.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: '123e4567-e89b-12d3-a456-426614174000',
      name: 'Rocket League',
      logoUrl: '/assets/images/logos/games/logo_rocket-league.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
    {
      id: '5d3ede65-4392-1234-b82b-e1fc0776ba4f',
      name: 'Valorant',
      logoUrl: '/assets/images/logos/games/logo_valorant.png',
      isActive: true,
      sub: [
        {
          id: '6d1f5128-299e-4eca-b092-b04edd922412',
          name: 'Gold',
          logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
          isActive: true,
        },
        {
          id: '179d25a5-15e9-4846-9a5a-955a01186687',
          name: 'Platinum',
          logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
          isActive: true,
        },
        {
          id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
          name: 'Diamond+',
          logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
          isActive: true,
        },
      ],
    },
  ],
};

/* Get all divisions for a league */
/*  '/api/league/:leagueName/division' */
/* /api/league/1234567/division */
Mock.onGet(/^\/api\/league\/([^/]+)\/divisions$/).reply((config: any) => {
  const response = DivisionsDB.list;
  return [200, response];
});

Mock.onGet('/api/division').reply((config: any) => {
  const response = DivisionsDB.list;
  return [200, response];
});

import { IUpcomingEvent } from '@dex/models/events/upcoming-event.model';
import Mock from '../mock';
import { MatchStyle } from '@dex/models/match-style.enum';

const start1 = new Date();
const start2 = new Date();
const start3 = new Date();
start1.setSeconds(-2032);
start2.setSeconds(-1400);
start3.setMinutes(75);

start3.toLocaleDateString('en-us', { hour: '2-digit' });

const UpcomingEventsDB: { list: IUpcomingEvent<any>[] } = {
  list: [
    {
      id: '0a639c13-560b-49b6-a924-60f36531a264',
      title: 'Live',
      matchStyle: MatchStyle.LEAGUE_OF_LEGENDS_SUMMONERS_RIFT,
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        isActive: true,
        sub: [],
      },
      subDivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
        isActive: true,
      },
      active: true,
      startDateTime: start1,
      eventBody: {
        home: {
          organizationId: '9511a108-544e-4daf-a94b-d2b03dc0ed09',
          code: 'BB',
          name: 'Bamboo',
          logoUrl: '/assets/images/logos/logo_bamboo.png',
          kda: { kills: 30, deaths: 15, assists: 31 },
        },
        away: {
          organizationId: '65581560-9232-4f64-83c2-0b7a692f1f7c',
          code: 'GL',
          name: 'Green Leaves',
          logoUrl: '/assets/images/logos/logo_green_leaves.png',
          kda: { kills: 15, deaths: 30, assists: 14 },
        },
      },
    },
    {
      id: '52ec4977-49ab-43de-9ddd-2c2fad1ad733',
      title: 'Live',
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      matchStyle: MatchStyle.LEAGUE_OF_LEGENDS_SUMMONERS_RIFT,
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        isActive: true,
        sub: [],
      },
      subDivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
        isActive: true,
      },
      eventBody: {
        home: {
          organizationId: '199ee020-bbdc-465e-8001-613f6ed20b1e',
          code: 'PK',
          name: 'Panda Kings',
          logoUrl: '/assets/images/logos/logo_panda_kings.png',
          kda: { kills: 12, deaths: 4, assists: 15 },
        },
        away: {
          organizationId: '44946b5e-15e0-47a8-9590-13d38975cbb5',
          code: 'LP',
          name: 'Lazy Pandas',
          logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
          kda: { kills: 4, deaths: 12, assists: 3 },
        },
      },
      active: true,
      startDateTime: start2,
    },
    {
      id: 'dfc1a19b-4f80-460d-8419-5f0d232a3957',
      title: 'Upcoming',
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      matchStyle: MatchStyle.LEAGUE_OF_LEGENDS_SUMMONERS_RIFT,
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        isActive: true,
        sub: [],
      },
      subDivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
        isActive: true,
      },
      eventBody: {
        home: {
          organizationId: '199ee020-bbdc-465e-8001-613f6ed20b1e',
          code: 'PK',
          name: 'Panda Kings',
          logoUrl: '/assets/images/logos/logo_panda_kings.png',
        },
        away: {
          organizationId: '44946b5e-15e0-47a8-9590-13d38975cbb5',
          code: 'LP',
          name: 'Lazy Pandas',
          logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
        },
      },
      active: false,
      startDateTime: start2,
    },
    {
      id: '6174dbbb-3463-473b-8b48-2b82ccb7655d',
      title: 'Upcoming',
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      matchStyle: MatchStyle.LEAGUE_OF_LEGENDS_SUMMONERS_RIFT,
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        isActive: true,
        sub: [],
      },
      subDivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
        isActive: true,
      },
      eventBody: {
        home: {
          organizationId: '199ee020-bbdc-465e-8001-613f6ed20b1e',
          code: 'PK',
          name: 'Panda Kings',
          logoUrl: '/assets/images/logos/logo_panda_kings.png',
        },
        away: {
          organizationId: '44946b5e-15e0-47a8-9590-13d38975cbb5',
          code: 'LP',
          name: 'Lazy Pandas',
          logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
        },
      },
      active: false,
      startDateTime: start2,
    },
    {
      id: '61e567ec-45f5-4ee3-a965-c6d39805d166',
      title: 'Upcoming',
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      matchStyle: MatchStyle.LEAGUE_OF_LEGENDS_SUMMONERS_RIFT,
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        isActive: true,
        sub: [],
      },
      subDivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
        isActive: true,
      },
      eventBody: {
        home: {
          organizationId: '199ee020-bbdc-465e-8001-613f6ed20b1e',
          code: 'PK',
          name: 'Panda Kings',
          logoUrl: '/assets/images/logos/logo_panda_kings.png',
        },
        away: {
          organizationId: '44946b5e-15e0-47a8-9590-13d38975cbb5',
          code: 'LP',
          name: 'Lazy Pandas',
          logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
        },
      },
      active: false,
      startDateTime: start2,
    },
    {
      id: 'e3678956-25bd-4562-9ec7-730033261e4e',
      title: 'Upcoming',
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      matchStyle: MatchStyle.LEAGUE_OF_LEGENDS_SUMMONERS_RIFT,
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        isActive: true,
        sub: [],
      },
      subDivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
        isActive: true,
      },
      eventBody: {
        home: {
          organizationId: '199ee020-bbdc-465e-8001-613f6ed20b1e',
          code: 'PK',
          name: 'Panda Kings',
          logoUrl: '/assets/images/logos/logo_panda_kings.png',
        },
        away: {
          organizationId: '44946b5e-15e0-47a8-9590-13d38975cbb5',
          code: 'LP',
          name: 'Lazy Pandas',
          logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
        },
      },
      active: false,
      startDateTime: start2,
    },
    {
      id: 'a60b8be3-8625-4246-82a9-8e3a45342297',
      title: 'Upcoming',
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      matchStyle: MatchStyle.LEAGUE_OF_LEGENDS_SUMMONERS_RIFT,
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        isActive: true,
        sub: [],
      },
      subDivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
        isActive: true,
      },
      eventBody: {
        home: {
          organizationId: '199ee020-bbdc-465e-8001-613f6ed20b1e',
          code: 'PK',
          name: 'Panda Kings',
          logoUrl: '/assets/images/logos/logo_panda_kings.png',
        },
        away: {
          organizationId: '44946b5e-15e0-47a8-9590-13d38975cbb5',
          code: 'LP',
          name: 'Lazy Pandas',
          logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
        },
      },
      active: false,
      startDateTime: start2,
    },
    {
      id: '0bc48290-7a09-4634-ad70-836b3b4a15e2',
      title: 'Upcoming',
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      matchStyle: MatchStyle.LEAGUE_OF_LEGENDS_SUMMONERS_RIFT,
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        isActive: true,
        sub: [],
      },
      subDivision: {
        id: '179d25a5-15e9-4846-9a5a-955a01186687',
        name: 'Platinum',
        logoUrl: '',
        isActive: true,
      },
      eventBody: {
        home: {
          organizationId: '199ee020-bbdc-465e-8001-613f6ed20b1e',
          code: 'PK',
          name: 'Panda Kings',
          logoUrl: '/assets/images/logos/logo_panda_kings.png',
        },
        away: {
          organizationId: '44946b5e-15e0-47a8-9590-13d38975cbb5',
          code: 'LP',
          name: 'Lazy Pandas',
          logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
        },
      },
      active: false,
      startDateTime: start2,
    },
    {
      id: 'e3b1aa5a-2f98-4d57-a68b-bb2ce118b1f0',
      title: 'Upcoming',
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      matchStyle: MatchStyle.LEAGUE_OF_LEGENDS_SUMMONERS_RIFT,
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        isActive: true,
        sub: [],
      },
      subDivision: {
        id: '179d25a5-15e9-4846-9a5a-955a01186687',
        name: 'Platinum',
        logoUrl: '',
        isActive: true,
      },
      eventBody: {
        home: {
          organizationId: '199ee020-bbdc-465e-8001-613f6ed20b1e',
          code: 'PK',
          name: 'Panda Kings',
          logoUrl: '/assets/images/logos/logo_panda_kings.png',
        },
        away: {
          organizationId: '44946b5e-15e0-47a8-9590-13d38975cbb5',
          code: 'LP',
          name: 'Lazy Pandas',
          logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
        },
      },
      active: false,
      startDateTime: start2,
    },
  ],
};

/* Gets upcoming events for a league by division */
/* '/api/league/{leagueName}/upcoming-events?division={divisionId}' */
Mock.onGet(/^\/api\/league\/\w+\/upcoming-events\/?divisionId=\w+$/).reply(
  (config: any) => {
    const response = UpcomingEventsDB.list.find((event: any) =>
      event.division.id !== 1 ? event.division.id === config.params.divisionId : true
    );
    return [200, response];
  }
);

/* Gets all upcoming events for a league */
/* '/api/league/{leagueName}/upcoming-events' */
Mock.onGet(/\/api\/league\/([^/]+)\/upcoming-events/).reply((config: any) => {
  const response = UpcomingEventsDB.list;
  return [200, response];
});

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';

import * as changeCase from 'change-case';

import { ILeague } from '@dex/models/league.model';

const baseUrl = process.env.REACT_APP_BFF_BASE_URL || 'https://bff.dexesports.gg';

export const fetchLeagues = createAsyncThunk(
  'division/fetchLeagues',
  async ({ pageNumber, pageSize }: { pageNumber?: number; pageSize?: number }) => {
    const response = await axios.get('/api/league');

    if (response.status !== 200) {
      throw new Error('Error fetching divisions');
    }
    return response.data as ILeague[];
  }
);

export const fetchLeagueByName = createAsyncThunk(
  'division/fetchLeagueByName',
  async (leagueName: string) => {
    const config: AxiosRequestConfig = {
      params: {
        snakeCasedName: changeCase.snakeCase(leagueName),
        inactiveDivisions: false,
        inactiveSubDivisions: false,
      },
    };
    const response = await axios.get(`${baseUrl}/organization/`, config);

    if (response.status !== 200) {
      throw new Error('Error fetching league by name');
    }
    return response.data as ILeague;
  }
);

export const fetchLeagueById = createAsyncThunk(
  'division/fetchLeagueById',
  async (leagueId: string) => {
    const response = await axios.get(`${baseUrl}/league/${leagueId}`);

    if (response.status !== 200) {
      throw new Error('Error fetching divisions');
    }
    return response.data as ILeague;
  }
);

const useFetchLeagues = (pageNumber?: number, pageSize?: number) => {
  const dispatch = useDispatch<AppDispatch>();
  const leagues = useSelector((state: RootState) => state.league.list);
  const loading = useSelector((state: RootState) => state.league.loading);
  const error = useSelector((state: RootState) => state.league.error);

  useEffect(() => {
    dispatch(fetchLeagues({ pageNumber, pageSize }));
  }, [dispatch, pageNumber, pageSize]);

  return { leagues, loading, error };
};

const useFetchLeagueByName = (leagueName: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const league = useSelector((state: RootState) => state.league.selectedLeague);
  const loading = useSelector((state: RootState) => state.league.loading);
  const error = useSelector((state: RootState) => state.league.error);

  useEffect(() => {
    dispatch(fetchLeagueByName(leagueName));
  }, [dispatch, leagueName]);

  return { league, loading, error };
};

const useFetchLeagueById = (leagueName: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const league = useSelector((state: RootState) => state.league.selectedLeague);
  const loading = useSelector((state: RootState) => state.league.loading);
  const error = useSelector((state: RootState) => state.league.error);

  useEffect(() => {
    dispatch(fetchLeagueById(leagueName));
  }, [dispatch, leagueName]);

  return { league, loading, error };
};

export { useFetchLeagues, useFetchLeagueByName, useFetchLeagueById };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { AppUser } from '@dex/models/app-user.model';
import { AppIdToken } from '@dex/models/app-id-token.model';

interface UserState {
  currentUser: AppUser | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  currentUser: null,
  loading: true, // Initially true, assuming the user is being loaded
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUser: (state, action: PayloadAction<string | null>) => {
      try {
        if (action.payload) {
          const decodedToken: AppUser = jwtDecode<AppIdToken>(action.payload);
          state.currentUser = { ...decodedToken };
        } else {
          state.currentUser = null;
        }
        state.loading = false;
      } catch (error) {
        console.error('Failed to decode JWT token:', error);
        state.error = 'Invalid token';
        state.loading = false;
      }
    },
    clearUser: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = null;
    },
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
export const { setLoading, setUser, clearUser } = userSlice.actions;

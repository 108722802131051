import { IDivision, ISubDivision } from '@dex/models/division.model';
import { IOrganization } from '@dex/models/organization.model';

type IAdminState = {
  selectedOrganization: IOrganization | null;
  selectedDivision: IDivision | null;
  selectedSubDivision?: ISubDivision | null;
  divisions: IDivision[];
  error: any;
  loading: boolean;
};

const initialAdminState: IAdminState = {
  selectedOrganization: null,
  selectedDivision: null,
  selectedSubDivision: null,
  divisions: [],
  error: null,
  loading: false,
};

export { initialAdminState };
export type { IAdminState };

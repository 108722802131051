import React, { useEffect, Fragment } from 'react';
import { useAuth } from 'app/auth/use-auth.hook';
import { setUserData } from 'app/redux/actions/UserActions';

const Auth = ({ children }: { children: any }) => {
  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      setUserData(user);
    }
  }, [isAuthenticated, user]);

  return <Fragment>{children}</Fragment>;
};

export { Auth };

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { IDivision } from '@dex/models/division.model';
import { ISeason } from '@dex/models/season.model';

const baseUrl = process.env.REACT_APP_BFF_BASE_URL || 'https://bff.dexesports.gg';

export const fetchDivisions = createAsyncThunk(
  'division/fetchDivisions',
  async ({
    leagueId,
    inactiveDivisions,
    inactiveSubDivisions,
  }: {
    leagueId: string;
    inactiveDivisions?: boolean;
    inactiveSubDivisions?: boolean;
  }) => {
    const url = `${baseUrl}/division/list/${leagueId}`;
    const config: AxiosRequestConfig = {
      params: {
        ...(inactiveDivisions !== undefined && { inactiveDivisions }),
        ...(inactiveSubDivisions !== undefined && { inactiveSubDivisions }),
      },
    };
    const response = await axios.get(url, config);

    if (response.status !== 200) {
      throw new Error('Error fetching divisions');
    }

    return response.data as IDivision[];
  }
);

export const fetchDivisionByOrganizationIdAndDivisionId = createAsyncThunk(
  'division/fetchDivisionByOrganizationIdAndDivisionId',
  async ({
    organizationId,
    divisionId,
  }: {
    organizationId: string;
    divisionId: string;
  }) => {
    const url = `${baseUrl}/division`;

    const config: AxiosRequestConfig = {
      params: {
        organizationID: organizationId,
        divisionId: divisionId,
      },
    };
    const response = await axios.get(url, config);

    if (response.status !== 200) {
      throw new Error('Error fetching division');
    }

    return response.data as IDivision;
  }
);

export const fetchSubDivisions = createAsyncThunk(
  'division/fetchDivisions',
  async (leagueId: string, divisionId) => {
    const config: AxiosRequestConfig = {
      params: {
        organizationId: leagueId,
        divisionId,
      },
    };
    const url = `${baseUrl}/division/subdivsion/list/`;
    const response = await axios.get(url, config);

    if (response.status !== 200) {
      throw new Error('Error fetching divisions');
    }

    return response.data as IDivision[];
  }
);

export const fetchSeason = createAsyncThunk(
  'division/fetchSeason',
  async (seasonId: string) => {
    const url = `${baseUrl}/season/${seasonId}`;
    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching season');
    }

    return response.data as ISeason;
  }
);

const useFetchDivisions = (
  leagueName: string,
  inactiveDivisions?: boolean,
  inactiveSubDivisions?: boolean
) => {
  const dispatch = useDispatch<AppDispatch>();
  const divisions = useSelector((state: RootState) => state.division.divisions);
  const loading = useSelector((state: RootState) => state.division.loading);
  const error = useSelector((state: RootState) => state.division.error);

  useEffect(() => {
    if (leagueName)
      dispatch(
        fetchDivisions({ leagueId: leagueName, inactiveDivisions, inactiveSubDivisions })
      );
  }, [dispatch, leagueName, inactiveDivisions, inactiveSubDivisions]);

  return { divisions, loading, error };
};

const useFetchDivisionByOrganizationIdAndDivisionId = (
  organizationId: string,
  divisionId: string
) => {
  const dispatch = useDispatch<AppDispatch>();
  const division = useSelector((state: RootState) => state.division.selectedDivision);
  const loading = useSelector((state: RootState) => state.division.loading);
  const error = useSelector((state: RootState) => state.division.error);

  useEffect(() => {
    if (organizationId && divisionId)
      dispatch(
        fetchDivisionByOrganizationIdAndDivisionId({ organizationId, divisionId })
      );
  }, [dispatch, organizationId, divisionId]);

  return { division, loading, error };
};

const useFetchSeason = (seasonId: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const season = useSelector(
    (state: RootState) => state.division.selectedSubDivision.selectedSeason.season
  );
  const loading = useSelector(
    (state: RootState) => state.division.selectedSubDivision.selectedSeason.loading
  );
  const error = useSelector(
    (state: RootState) => state.division.selectedSubDivision.selectedSeason.error
  );

  useEffect(() => {
    if (seasonId) dispatch(fetchSeason(seasonId));
  }, [dispatch, seasonId]);

  return { season, loading, error };
};

export {
  useFetchDivisions,
  useFetchDivisionByOrganizationIdAndDivisionId,
  useFetchSeason,
};

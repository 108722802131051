import {
  ISearchResult,
  PersonaType,
  PersonaStatus,
} from '@dex/models/search-result.model';
import Mock from '../mock';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

const SearchDB: { list: ISearchResult[] } = {
  list: [
    {
      id: '34567890',
      name: 'Localhost - Denver Chapter',
      divisions: [
        { name: 'Call of Duty', id: '', logoUrl: '', isActive: true, sub: [] },
        {
          name: 'Counter-Strike: Global Offensive',
          id: '',
          logoUrl: '',
          isActive: true,
          sub: [],
        },
        { name: 'Counter-Strike 2', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'League of Legends', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Mario Kart', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Smash Brothers', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Valorant', id: '', logoUrl: '', isActive: true, sub: [] },
      ],
      brand: {
        logo: {
          full: '/assets/images/logos/logo_localhost-denver-chapter.png',
          mobile: '/assets/images/logos/logo_localhost-denver-chapter.png',
        },
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
      },
      type: PersonaType.LEAGUE,
      status: PersonaStatus.RECRUITING,
    },
    {
      id: 'd11def86-763e-43f4-94f7-6dba0213b149',
      name: 'NawBrah',
      brand: {
        logo: {
          full: '/assets/images/players/nawbrah.jpg',
          mobile: '/assets/images/players/nawbrah.jpg',
        },
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
      },
      divisions: [
        {
          name: 'Counter-Strike: Global Offensive',
          id: '',
          logoUrl: '',
          isActive: true,
          sub: [],
        },
        { name: 'Counter-Strike 2', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'League of Legends', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Mario Kart', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Rocket League', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Valorant', id: '', logoUrl: '', isActive: true, sub: [] },
      ],
      type: PersonaType.PLAYER,
      status: PersonaStatus.LOOKING_FOR_TEAM,
    },
    {
      id: '23456789',
      name: 'Titan Esports',
      brand: {
        logo: {
          full: '/assets/images/logos/logo_titan-esports.png',
          mobile: '/assets/images/logos/logo_titan-esports.png',
        },
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
      },
      divisions: [
        { name: 'League of Legends', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Valorant', id: '', logoUrl: '', isActive: true, sub: [] },
      ],
      type: PersonaType.LEAGUE,
      status: PersonaStatus.RECRUITING,
    },
    {
      id: '12345678',
      name: 'Pandamonium League',
      brand: {
        logo: {
          full: '/assets/images/logos/logo_pandamonium-league.png',
          mobile: '/assets/images/logos/logo_pandamonium-league.png',
        },
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
      },
      divisions: [
        { name: 'Call of Duty', id: '', logoUrl: '', isActive: true, sub: [] },
        {
          name: 'Counter-Strike: Global Offensive',
          id: '',
          logoUrl: '',
          isActive: true,
          sub: [],
        },
        { name: 'Counter-Strike 2', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'League of Legends', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Rocket League', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Valorant', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Overwatch', id: '', logoUrl: '', isActive: true, sub: [] },
      ],
      type: PersonaType.LEAGUE,
      status: PersonaStatus.RECRUITING,
    },
    {
      id: '45678901',
      name: 'Mizzou Esports',
      brand: {
        logo: {
          full: '/assets/images/logos/collegiate/logo_mizzou-esports.png',
          mobile: '/assets/images/logos/collegiate/logo_mizzou-esports.png',
        },
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
      },
      divisions: [
        { name: 'Call of Duty', id: '', logoUrl: '', isActive: true, sub: [] },
        {
          name: 'Counter-Strike: Global Offensive',
          id: '',
          logoUrl: '',
          isActive: true,
          sub: [],
        },
        { name: 'League of Legends', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Valorant', id: '', logoUrl: '', isActive: true, sub: [] },
      ],
      type: PersonaType.UNIVERSITY,
      status: PersonaStatus.RECRUITING,
    },
    {
      id: '56789012',
      name: 'LSU Esports',
      brand: {
        logo: {
          full: '/assets/images/logos/collegiate/logo_lsu-esports.png',
          mobile: '/assets/images/logos/collegiate/logo_lsu-esports.png',
        },
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
      },
      divisions: [
        { name: 'Call of Duty', id: '', logoUrl: '', isActive: true, sub: [] },
        {
          name: 'Counter-Strike: Global Offensive',
          id: '',
          logoUrl: '',
          isActive: true,
          sub: [],
        },
        { name: 'League of Legends', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Valorant', id: '', logoUrl: '', isActive: true, sub: [] },
      ],
      type: PersonaType.UNIVERSITY,
      status: PersonaStatus.RECRUITING,
    },
    {
      id: '67890123',
      name: 'CSU Esports',
      brand: {
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
        logo: {
          full: '/assets/images/logos/collegiate/logo_csu-esports.png',
          mobile: '/assets/images/logos/collegiate/logo_csu-esports.png',
        },
      },
      divisions: [
        {
          name: 'Counter-Strike: Global Offensive',
          id: '',
          logoUrl: '',
          isActive: true,
          sub: [],
        },
        { name: 'League of Legends', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Rocket League', id: '', logoUrl: '', isActive: true, sub: [] },
        { name: 'Valorant', id: '', logoUrl: '', isActive: true, sub: [] },
      ],
      type: PersonaType.UNIVERSITY,
      status: PersonaStatus.RECRUITING,
    },
  ],
};

Mock.onGet(/\/api\/search\/.*/).reply((config: AxiosRequestConfig) => {
  // Extract the search term from the URL
  const term = config.url?.split('/').pop();

  // Filter the item list based on the search term
  const filteredItems = term
    ? SearchDB.list.filter((item) => item.name.toLowerCase().includes(term.toLowerCase()))
    : SearchDB.list; // Return the entire item list if the search term is empty

  // Return the filtered items as the response
  const response: AxiosResponse<ISearchResult[]> = {
    status: 200,
    statusText: 'OK',
    data: filteredItems.sort((a, b) => (a.name > b.name ? 1 : -1)),
    config: config,
    headers: {},
  };

  return [200, response];
});

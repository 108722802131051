import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setAccessToken, setIdToken } from '../redux/slices/auth.slice';
import { setUser, setLoading } from '../redux/slices/user.slice';

const useAuth = () => {
  const {
    isAuthenticated,
    isLoading: auth0Loading,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    const getTokenAndUser = async () => {
      if (auth0Loading) {
        dispatch(setLoading(true));
        return;
      }

      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const idToken = await getAccessTokenSilently({ detailedResponse: true });

          dispatch(setAccessToken(accessToken));
          dispatch(setUser(idToken.id_token));
          dispatch(setIdToken(idToken.id_token));
          dispatch(setLoading(false));
        } catch (error) {
          console.error('Error getting tokens:', error);
          dispatch(setLoading(false));
        }
      } else {
        dispatch(setLoading(false));
      }
    };

    getTokenAndUser();
  }, [auth0Loading, isAuthenticated, user, getAccessTokenSilently, dispatch]);

  // Return useful values for the consuming component
  return {
    isAuthenticated,
    isLoading: auth0Loading,
    user,
  };
};

export { useAuth };
